<!-- eslint-disable vue/valid-v-model -->
<template>
  <div style="margin: 0; padding: 0" class="table-responsive">
    <confirm-modal ref="confirmModal" />
    <table id="user-table" class="table tb-dnd table-sm">
      <colgroup>
        <col style="width: 60px" />
        <col style="width: 250px" />
        <col style="width: 120px" />
        <col style="width: 300px" />
        <col style="width: 190px" />
        <col style="" />
      </colgroup>
      <thead>
        <tr>
          <th :class="isEditing ? 'edit-th-content text-center' : 'normal-th-content text-center'">
            <b-form-checkbox id="checkbox-all" size="lg" :disabled="!isEditing" name="checkbox-all" :value="true"
                             :unchecked-value="false" :checked="
                               allItems.length > 0 && allItems.length === checkedItems.length
                             " @change="checkAll($event)"
            >
            </b-form-checkbox>
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            EMAIL
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            USER ROLE
            <img id="tooltip-user-role" style="width: 20px; height: 20px" src="@/assets/icons/tooltip.png" />
          </th>
          <b-tooltip target="tooltip-user-role" triggers="hover" placement="bottom" custom-class="user-role-tooltip">
            <div>
              <div>
                Global: Full admin access to all countries (Only available for
                Global account holders)
              </div>
              <div>
                Country: Full admin access to the specified country only
              </div>
              <div>Standard: Admin access to password only</div>
            </div>
          </b-tooltip>
          <th v-if="isShowCountryAccess()" :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            COUNTRY ACCESS
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            LAST LOGIN
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            DATA ACCESS
            <img id="tooltip-data-access" style="width: 20px; height: 20px" src="@/assets/icons/tooltip.png" />
            <b-tooltip target="tooltip-data-access" triggers="hover" placement="bottom"
                       custom-class="user-role-tooltip"
            >
              <div>
                <div>To get data access, please contact a global representative.</div>
              </div>
            </b-tooltip>
          </th>
          <th :class="isEditing ? 'edit-th-content text-right' : 'normal-th-content text-right'">
            <b-button v-if="isEditing" id="delete-selected" variant="outline-info" class="mb-2" :disabled="disabledDeleteButton"
                      @click="deleteSelected()"
            >
              Delete Selected
            </b-button>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(user, index) in allItems" :key="index">
          <td class="text-center">
            <b-form-checkbox :id="`checkbox-${index}`" size="lg" :name="`checkbox-${index}`"
                             :disabled="!isEditing || !itemCannotDelete.includes(user)" :checked="hasChecked(index)"
                             @change="checkItem(index)"
            >
            </b-form-checkbox>
          </td>
          <td>
            <b-form-input v-if="user.isNew && isEditing" v-model="user.email" type="email"
                          @change="emitChange"
            ></b-form-input>
            <label v-else class="mt-2">
              {{ user.email }}
            </label>
          </td>
          <td>
            <b-form-select v-if="(isEditCountryAccess(user) || user.isNew)" v-model="user.role"
                           :options="userRoleOptions()" @change="emitChange"
            ></b-form-select>
            <label v-else class="mt-2">
              {{ getRoleName(user.role) }}
            </label>
          </td>
          <td v-if="isShowCountryAccess()">
            <!-- <multiselect v-if="isEditing && !isUserGlobal(user)"  -->
            <multiselect v-if="(isEditCountryAccess(user) && !isUserGlobal(user))" v-model="user.dataAccessCountryListOptions"
                         :value="value" :options="ddlCountriesName(user)" track-by="name" label="name" :multiple="true" :max-height="150" @input="onChange"
            >
              <template slot="tag" slot-scope="props">
                <span :class="props.option.isDisabled != true ? 'multiselect__tag' : 'multiselect__tag multiselect__tag--disabled'"><span>{{ props.option.name }}</span> <i v-if="props.option.isDisabled != true" tabindex="1" class="multiselect__tag-icon" @click="props.remove(props.option)"></i></span>
              </template>
            </multiselect>
            
            <label v-else class="mt-2">
              {{ user.dataAccessCountry }}
            </label>
          </td>
          <td>
            <label v-if="user.lastLogin" class="mt-2">
              {{ formatDateTime(user.lastLogin) }}
            </label>
          </td>
          <td v-if="isEditDataAccess(user) && !isUserGlobal(user)">
            <b-form-select v-if="isEditDataAccess(user)" v-model="user.dataAccess" :options="dataAccessOptions()"
                           @change="emitChange"
            ></b-form-select>
            <label v-else class="mt-2">
              {{ getDataAccess(user.dataAccess) }}
            </label>
          </td>
          <td v-else class="mt-2">
            <label>
              {{ getDataAccess(user.dataAccess) }}
            </label>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <hr />
    <div class="mb-5">
      <button class="btn btn-primary width-76 px-5" style="margin-left: 60px" :disabled="!isEditing" @click="add">
        + add more users
      </button>
    </div>
    <table id="table-block" class="table tb-dnd table-sm position-fixed" style="display: none;">
      <colgroup>
        <col style="width: 60px" />
        <col style="width: 250px" />
        <col style="width: 120px" />
        <col style="width: 300px" />
        <col style="width: 190px" />
        <col style="" />
      </colgroup>
      <thead>
        <tr>
          <th :class="isEditing ? 'edit-th-content text-center' : 'normal-th-content text-center'">
            <b-form-checkbox id="checkbox-all" size="lg" :disabled="!isEditing" name="checkbox-all" :value="true"
                             :unchecked-value="false" :checked="
                               allItems.length > 0 && allItems.length === checkedItems.length
                             " @change="checkAll($event)"
            >
            </b-form-checkbox>
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            EMAIL
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            USER ROLE
            <img id="tooltip-user-role" style="width: 20px; height: 20px" src="@/assets/icons/tooltip.png" />
          </th>
          <b-tooltip target="tooltip-user-role" triggers="hover" placement="bottom" custom-class="user-role-tooltip">
            <div>
              <div>
                Global: Full admin access to all countries (Only available for
                Global account holders)
              </div>
              <div>
                Country: Full admin access to the specified country only
              </div>
              <div>Standard: Admin access to password only</div>
            </div>
          </b-tooltip>
          <th v-if="isShowCountryAccess()" :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            COUNTRY ACCESS
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            LAST LOGIN
          </th>
          <th :class="isEditing ? 'edit-th-content' : 'normal-th-content'">
            DATA ACCESS
            <img id="tooltip-data-access" style="width: 20px; height: 20px" src="@/assets/icons/tooltip.png" />
            <b-tooltip target="tooltip-data-access" triggers="hover" placement="bottom"
                       custom-class="user-role-tooltip"
            >
              <div>
                <div>To get data access, please contact a global representative.</div>
              </div>
            </b-tooltip>
          </th>
          <th :class="isEditing ? 'edit-th-content text-right' : 'normal-th-content text-right'">
            <b-button v-if="isEditing" id="delete-selected" variant="outline-info" class="mb-2" :disabled="disabledDeleteButton"
                      @click="deleteSelected()"
            >
              Delete Selected
            </b-button>
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import $ from 'jquery';
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import PlatformService from "../../../../../src/service/PlatformService";
import { MODAL_SAVE, OK_CONFIRM } from "../../../../common/CommonUtils";
import { toStandardDateTime } from "../../../../common/DateTimeUtils";
import { SPECIAL_ACCOUNT_CAN_NOT_DELETE } from "../../../../constants/SpecialAccount";
import {
COUNTRY_ROLE_NAMES, DATA_ACCESS, GLOBAL_COUNTRY_CODE, GLOBAL_COUNTRY_NAME,
ROLE_ADMIN, ROLE_COUNTRY,
ROLE_NAMES
} from "../../../../constants/UserRole";
import ConfirmModal from "../../../common/ConfirmModal.vue";

// register globally
Vue.component('Multiselect', Multiselect)

export default {
  name: "Manageuser",
  components: { ConfirmModal },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    isGlobal: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isDisabled: false,
      isTouched: false,
      allItems: [],
      checkedItems: [],
      deletedItems: [],
      countriesPlatform: [],
      value: [],
      top: 0,
    };
  },
  computed: {
    currentUserLoginRole() {
      return this.currentUser.country.name;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentUserAccessCountriesCodes() {
      return this.currentUser.dataAccessCountryList.split(";");
    },
    currentUserAccessCountriesNames() {
      const codes = [];
      if (this.currentUser.role === ROLE_ADMIN) {
        return codes;
      }
      for (const [key, value] of Object.entries(this.countriesPlatform)) {
        if (this.currentUserAccessCountriesCodes.includes(key)) {
              codes.push(value);
        }
      }
      return codes;
    },
    disabledDeleteButton() {
      return this.checkedItems.length === 0;
    },
    itemCannotDelete() {
      return this.allItems.filter(
        (i) =>
          ![
            ...SPECIAL_ACCOUNT_CAN_NOT_DELETE,
            this.currentUser.username,
          ].includes(i.email)
      );
    },
  },
  watch: {
    isEditing() {
      this.checkedItems = [];
      this.deletedItems = [];
    },
    items() {
      console.log("watching items")
      // this.allItems = this.items;
      const newItems = [];
      this.items.forEach(user =>  {
        user.dataAccessCountryListOptions = this.ddlCountriesNameOptions(user);
        newItems.push(user);
      })
      this.allItems = newItems;
    },
  },
  updated() {
    const menu = $(".action-menu");
    let height = 223;
    if(menu.length) height = menu.height() + 65;
    this.top = height;
  },
  mounted() {
    PlatformService.getAll().then((response) => {
      const { data } = response;
      this.countriesPlatform = data.reduce(
        (obj, item) => ((obj[item.countryCode] = item.countryName), obj),
        {}
      );
      const newItems = [];
      this.items.forEach(user =>  {
        user.dataAccessCountryListOptions = this.ddlCountriesNameOptions(user);
        newItems.push(user);
      })
      console.log("mounting items")
      this.allItems = newItems;
    });
    this.windowOnScroll();
  },
  methods: {
    onChange(value) {
      this.value = value
    },
    userRoleOptions() {
      const currentUser = this.currentUser;
      if (!currentUser) return [];
      const roleList =
        currentUser.role === ROLE_ADMIN ? ROLE_NAMES : COUNTRY_ROLE_NAMES;

      return Object.keys(roleList).map((key) => {
        return {
          value: key,
          text: roleList[key],
        };
      });
    },
    dataAccessOptions() {
      const currentUser = this.currentUser;
      const dataAccessList = DATA_ACCESS

      if (!currentUser) return [];

      return Object.keys(dataAccessList).map((key) => {
        return {
          value: key,
          text: dataAccessList[key],
        };
      });
    },
    isShouldEditAccess(user) {
      const isEditingCondition = this.isEditing
      return user.dataAccess !== 0 && isEditingCondition;
    },
    isShowCountryAccess() {
      const currentUser = this.currentUser;
      return currentUser.role === ROLE_ADMIN || currentUser.role === ROLE_COUNTRY;
    },
    isUserGlobal(user) {
      if (user.role === ROLE_ADMIN) {
        user.countryCode = GLOBAL_COUNTRY_CODE;
        user.countryName = ROLE_NAMES[ROLE_ADMIN];
        user.dataAccess = 1;
        user.dataAccessCountry = GLOBAL_COUNTRY_NAME;
        user.dataAccessCountryList = GLOBAL_COUNTRY_NAME;
        return true
      }
      return false;
    },
    isEditCountryAccess(user) {
      const isEditingCondition = this.isEditing
      const currentUser = this.currentUser;
      if (currentUser.role === ROLE_ADMIN || currentUser.role === ROLE_COUNTRY) {
        if (currentUser.username === user.email) {
          return false;
        }
        if (user.isNew) {
          return true;
        }
        return isEditingCondition && !user.isNew;
      } else {
        return false;
      }
    },
    isEditDataAccess(user) {
      const isEditingCondition = this.isEditing
      const currentUser = this.currentUser;
      if (currentUser.role === ROLE_ADMIN) {
        if (currentUser.username === user.email) {
          return false;
        }
        if (user.isNew) {
          return true;
        }
        return isEditingCondition && !user.isNew;
      } else {
        return false;
      }
    },
    ddlCountries(user) {
      if (user.role === ROLE_ADMIN) {
        user.countryCode = GLOBAL_COUNTRY_CODE;
        user.countryName = ROLE_NAMES[ROLE_ADMIN];
        user.dataAccessCountry = GLOBAL_COUNTRY_CODE;
        user.dataAccessCountryList = GLOBAL_COUNTRY_CODE;
        return [{ value: GLOBAL_COUNTRY_CODE, text: ROLE_NAMES[ROLE_ADMIN] }];
      }
      const allCountry = this.countriesPlatform;
      const results = [];
      for (const [key, value] of Object.entries(allCountry)) {
        results.push({
          value: key,
          text: value,
        });
      }
      if (user.role !== ROLE_ADMIN) {
        return results.filter((i) => i.value !== GLOBAL_COUNTRY_CODE);
      }
      return results;
    },
    ddlCountriesNameOptions(user) {
      if (user.role === ROLE_ADMIN) {
        return [];
      }
      if (user.dataAccessCountryList === undefined || user.dataAccessCountryList=== null) {
        return [];
      }
      const results = [];
      const currentUser = this.currentUser;
      user.dataAccessCountryList.forEach(country => {
        let isDisabled = false;
        if (currentUser.role === ROLE_COUNTRY && !this.currentUserAccessCountriesNames.includes(country)) {
          isDisabled = true;
        }
        results.push({"name": country, $isDisabled:isDisabled, "isDisabled": isDisabled});
      })
      return results;
    },
    ddlCountriesName(user) {
      if (user.role === ROLE_ADMIN) {
        return [ROLE_NAMES[ROLE_ADMIN]];
      } else {
        const currentUser = this.currentUser;
        const allCountry = this.countriesPlatform;
        const results = [];
        if (user.dataAccessCountryList === GLOBAL_COUNTRY_NAME) {
          user.dataAccessCountryList = null;
        }
        for (const [key, value] of Object.entries(allCountry)) {
          if (key === GLOBAL_COUNTRY_CODE) {
            continue;
          }
          if (currentUser.role === ROLE_COUNTRY) {
            if (this.currentUserAccessCountriesCodes.includes(key)) {
              results.push({"name": value})
            // } else if (user.dataAccessCountryList.includes(value)) {
              // results.push({"name": value, $isDisabled: true})
            }
          }
          else {
            results.push({"name": value});
          }
        }
        return results;
      }
    },
    convertStrToArr(user) {
      return user.dataAccessCountry.split(",");
    },
    formatDateTime(date) {
      return toStandardDateTime(date);
    },
    hasChecked(index) {
      return this.checkedItems.includes(index);
    },
    getRoleName(role) {
      return ROLE_NAMES[role] || "";
    },
    getDataAccess(dataAccess) {
      return DATA_ACCESS[dataAccess] || "No";
    },
    add() {
      // Dũng - ẩn chỗ code này để không giới hạn số lượng user được thêm mỗi lần edit
      // if (
      //   this.allItems.length &&
      //   !this.allItems[this.allItems.length - 1].countryCode
      // )
      //   return;

      const currentUser = this.currentUser;
      if (currentUser.role === ROLE_COUNTRY) {
        const newCountryList = [];
        this.currentUserAccessCountriesNames.forEach (country => {
          newCountryList.push({"name": country})
        })
        console.log(currentUser);
        this.allItems.push({
          isNew: true,
          role: null,
          email: "",
          countryCode: null,
          countryName: null,
          dataAccessCountry: this.currentUserAccessCountriesNames.join(";"),
          dataAccessCountryList: this.currentUserAccessCountriesNames,
          dataAccessCountryListOptions: newCountryList
        });
      } else {
        this.allItems.push({
          isNew: true,
          role: null,
          email: "",
          countryCode: null,
          countryName: null,
        });
      }
    },
    deleteSelected() {
      if (this.checkedItems.length === 0) return;
      this.$refs.confirmModal.open(MODAL_SAVE, (data) => {
        if (data === OK_CONFIRM) {
          const checkedEmails = this.allItems
            .filter((item, i) => this.checkedItems.includes(i))
            .map((x) => x.email);
          const allItems = this.allItems.filter(
            (item, i) => !this.checkedItems.includes(i)
          );
          this.deletedItems = [...this.deletedItems, ...checkedEmails];
          this.checkedItems = [];
          this.allItems = allItems;
          this.emitChange();
        }
      });
    },
    checkAll(isAll) {
      if (isAll) {
        this.checkedItems = Array.from(Array(this.items.length).keys());
      } else {
        this.checkedItems = [];
      }
    },
    checkItem(index) {
      if (this.hasChecked(index)) {
        this.checkedItems.splice(
          this.checkedItems.findIndex((x) => x == index),
          1
        );
      } else {
        this.checkedItems.push(index);
      }
    },
    changeEmail(index, email) {
      if (!this.allItems[index]) return;
      this.allItems[index].email = email;

      this.checkToEmit(this.allItems[index]);
    },
    changeRole(index, role) {
      if (!this.allItems[index]) return;
      this.allItems[index].role = role;

      this.checkToEmit(this.allItems[index]);
    },
    changeCountryCode(index, countryCode) {
      const countryName = this.countriesPlatform[countryCode];
      if (!this.allItems[index]) return;
      this.allItems[index].countryCode = countryCode;
      this.allItems[index].countryName = countryName;
      this.checkToEmit(this.allItems[index]);
    },
    checkToEmit(user) {
      if (!user) return;
      if (!user.email || !user.role || !user.countryCode) return;

      this.emitChange();
    },
    emitChange() {
      this.$emit("onUpdate", this.allItems, this.deletedItems);
    },
    windowOnScroll () {
      $(window).bind("scroll", (e) => {
        const currentTDWidth = $("#user-table").width();
        const storageThWidth = [];
        let ind = 0;
        $("#user-table th").each(function() {
          storageThWidth.push($(this)[0].clientWidth);
        });
        $("#table-block th").each(function() {
          $(this)[0].style.width = storageThWidth[ind++] + "px";
        });
        let offset = $(e.target).scrollTop();
        if(offset > 65) {
          $("#table-block").css({"width":currentTDWidth,"top": `calc(${this.top}px + 3rem)`, "display": "table", "zIndex": 1000});
        }else {
          $("#table-block").css({"display": "none"});
        }
      });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}
</style>
<style>
.tooltip.user-role-tooltip>.tooltip-inner {
  max-width: 1000px;
  white-space: nowrap;
  text-align: left;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<style>
.multiselect__tag--disabled {
  /* background: #f0f0f0 !important; */
  background: #ededed!important;
  color: #768192!important;
}

</style>
