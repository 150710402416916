<template>
  <div>
    <confirm-modal ref="confirmModal" />
    <div :class="'action-menu pt-5'" :style="{top: headerHeight + 'px'}">
      <h2 class="head-title">
        Manage product access for all the users in your organization.
      </h2>
      <b-row>
        <b-col cols="6" md="8">
          <div class="mb-3">
            <p>Create and manage accounts and user’s access</p>
          </div>
          <div class="d-flex">
            <b>Note:&nbsp;</b>
            <div>
              <div class="vw-100">
                It is recommended to do a yearly revision of the users in your
                organization. Update or remove inactive users on a yearly basis.
              </div>
              <div>
                To delete your own account, please approach the account manager.
              </div>
            </div>
          </div>
        </b-col>

        <b-col class="text-right" cols="6" sm="4">
          <template v-if="isEditing">
            <b-button variant="primary" class="mb-2 mr-2 px-5" @click="save">
              Save
            </b-button>
            <b-button variant="outline-info" class="mb-2 px-5" @click="unSave">
              Cancel
            </b-button>
          </template>
          <b-button v-if="!isEditing" variant="outline-info" class="mb-2 px-5" @click="setEditing(true)">
            Edit content
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div class="user-table">
      <UserTable :is-editing="isEditing" :is-global="isGlobal" :items="items" @onUpdate="onUpdate" />
    </div>
  </div>
</template>

<script>
import ArrayUtils from "../../../common/ArrayUtils";
import {
MODAL_CANCEL,
MODAL_SAVE,
OK_CONFIRM,
} from "../../../common/CommonUtils";
import ManageService from "../../../service/ManageUserService";
import ConfirmModal from "../../common/ConfirmModal.vue";

import { PublicClientApplication } from "@azure/msal-browser";
import Vue from "vue";
import {
ROLE_ADMIN
} from "../../../constants/UserRole";
import UserTable from "./components/UserTable";

export default {
  name: "ManageUser",
  components: {
    UserTable,
    ConfirmModal,
  },
  data() {
    return {
      items: [],
      originalItems: [],
      deletedItems: [],
      isEditing: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    isGlobal() {
      const user = this.user;
      return user && user.isGlobal;
    },
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    },
    getTableHeaderClass() {
        return this.isEditing
            ? "edit-th-content"
            : "normal-th-content bg-light"
    },
  },
  created() {
    this.getAll();
    this.$msalInstance = new PublicClientApplication(
        this.$store.state.azure.msalConfig,
    )
  },
  methods: {
    setEditing(isEditing) {
      this.isEditing = isEditing || !this.isEditing;
    },
    getAll() {
      const loader = this.$loading.show();
      ManageService.getAll()
        .then((response) => {
          loader.hide();
          this.items = response.data;
          this.originalItems = ArrayUtils.clone(this.items);
        })
        .catch(this.catchError)
        .finally(() => {
          loader.hide();
        });
    },
    onUpdate(items, deletedItems) {
      this.items = items;
      this.deletedItems = deletedItems;
    },
    save() {
      
      const uniqueEmail = new Set(this.items.map((i) => i.email));
      let isRequestValid = true;
      let isRequestEmailValid = true;
      this.items.forEach((item) => {
        if (item.role != ROLE_ADMIN) {
          const newList = [];
          item.dataAccessCountryListOptions.forEach(option => {
            newList.push(option["name"]);
          })
          item.dataAccessCountryList = newList;
        }
         if (!item.dataAccessCountryList || item.dataAccessCountryList.length === 0) {
          console.log(item);
          isRequestValid = false;
        }
        if(!this.validateEmail(item.email)) {
          isRequestEmailValid = false;
        }
      })
      if (!isRequestValid) {
        return this.$toast.open({
          message: "Invalid Country.",
          type: "error",
        });
      }
      if (uniqueEmail.size < this.items.length) {
        return this.$toast.open({
          message: "The specified email address already exist.",
          type: "error",
        });
      }
      if (!isRequestEmailValid) {
        return this.$toast.open({
          message: "Invalid Email.",
          type: "error",
        });
      }
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: Vue.$cookies.get("account")
      };
      this.$msalInstance.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
        let accessToken = accessTokenResponse.accessToken;
        Vue.$cookies.set('token_azure', accessToken);
      }).catch(() => {
        this.$msalInstance.acquireTokenPopup(accessTokenRequest).then(function (accessTokenResponse) {
          let accessToken = accessTokenResponse.accessToken;
          Vue.$cookies.set('token_azure', accessToken);
        })
      })
      this.$refs.confirmModal.open(MODAL_SAVE, (data) => {
        if (data === OK_CONFIRM) {
          const loader = this.$loading.show();
          if (this.deletedItems.length) {
            ManageService.delete(this.deletedItems)
              .then(() => {
                console.log("Item deleted");
                this.deletedItems = [];
              })
              .catch(this.catchError);
          }
          // if user is country, add country code to other user
          if (!this.isGlobal) {
            this.items = this.items.map((x) => {
              if (!x.countryCode) {
                x.countryCode = this.user.country?.code;
              }
              return x;
            });
          }
          ManageService.upsert(this.items)
            .then(() => {
              this.setEditing(false);
              this.getAll();
            })
            .catch(this.catchError)
            .finally(() => {
              loader.hide();
            });
        }
      });
    },
    unSave() {
      this.$refs.confirmModal.open(MODAL_CANCEL, (data) => {
        if (data === OK_CONFIRM) {
          this.isEditing = false;
          this.items = ArrayUtils.clone(this.originalItems);
        }
      });
    },
    catchError(reason) {
      const data = reason.response.data;
      console.error(data);
    },
    validateEmail(email) {
      return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    }
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}

.user-table {
  margin-top: 1rem;
}
</style>